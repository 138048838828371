import axiosInstance from "../../axios";
import { TSnapshotType } from "../../components/heatmap/types";
import { TSelectedCompany } from "../../store";

import { v4 as uuidv4 } from "uuid";

export const heatmap = {
  snapshots: async (selectedCompany: TSelectedCompany, snapshotType: TSnapshotType, range: { startDate: string, endDate: string }) => {
    try {
      const response = await axiosInstance.get(
        range.startDate ? '/snapshots/snapshots-by-range' : `/snapshots/snapshots/all-time`,
        {
          params: {
            eldType: selectedCompany,
            snapshotType,
            ...range
          }
        }
      );

      return response.data;
    } catch (error) {
      console.error("List error:", error);
      throw error;
    }
  }
};
