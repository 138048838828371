import React from "react";
import "./style.scss";
import StatisticsCard from "./components/StatisticsCard/StatisticsCard";
import useStore from "../../store";
import Loader from "../../../common/ui/loader/Loader";

interface CompaniesStatisticsProps {
  statisticType: string;
}

const CompaniesStatistics: React.FC<CompaniesStatisticsProps> = ({
  statisticType,
}) => {
  const dataMap: { [key: string]: any } = {
    company: useStore(state => state.companyData),
    subscription: useStore(state => state.subscriptionData),
    vehicle: useStore(state => state.vehicleData),
    vehicle_stats: useStore(state => state.vehicleStatsData),
    user: useStore(state => state.userData),
    driver: useStore(state => state.driverData),
    driver_stats: useStore(state => state.driverStatsData),
  };

  const data = dataMap[statisticType];
  const data_stats = dataMap[`${statisticType}_stats`];

  return (
    <div
      style={{ position: "relative", minHeight: "400px", gap: "24px" }}
      className='d-flex flex-column'
    >
      {data ? (
        <>
          <StatisticsCard
            title={data.title}
            header={data.header}
            cards={data.cards}
            chart={data.chart}
          />
          {data_stats ? (
            <StatisticsCard
              title={data_stats.title}
              header={data_stats.header}
              cards={data_stats.cards}
              chart={data_stats.chart}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CompaniesStatistics;
