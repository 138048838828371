import { useCallback, useState } from "react";
import { useGlobalStore } from "../../store";
import { useQuery } from "react-query";
import { heatmap } from "../../api/heatmap";
import GoogleMapsHeatmap from "../../api/heatmap/GoogleMapsHeatmap";
import { TSnapshotType } from "./types";
import { Radio } from "../common/form/Radio";
import SelectComponent from "../common/ui/heatmapSelect/dropdown";
import moment from "moment";
import { dateToIso } from "../utils";
import "./heatmap.scss";

export const HeatMap = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSnapshotType>("pickups");
  const [range, setRange] = useState({ startDate: '', endDate: '' });
  const [selectedDateType, setSelectedDateType] = useState('all-time');
  const values: { label: string, value: TSnapshotType }[] = [{ label: "Pickups", value: "pickups" }, { label: "Drops", value: "drops" }, { label: "Dots", value: "dots" }];
  const selectedCompany = useGlobalStore(state => state.selectedCompany);
  const { data, isLoading } = useQuery(
    ["heatmap", selectedCompany, selectedOption, range],
    () => heatmap.snapshots(selectedCompany, selectedOption, range),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );  

  const items = [
    { id: 'all-time', name: 'All time' },
    { id: 'year', name: 'Year' },
    { id: 'month', name: 'Month' },
    { id: 'week', name: 'Week' },
    { id: 'today', name: 'Today' },
    { id: 'custom', name: 'Custom Range' }
  ];

  const handleSelect = useCallback((selectedValue: string) => {
    const today = moment();
    let startDate = '';
    let endDate = today.format('DD-MM-YYYY');
    if(selectedValue === 'custom') {
      setSelectedDateType('custom');
      return;
    }
    switch (selectedValue) {
      case 'all-time':
        startDate = '';
        endDate = '';
        break;
  
      case 'year':
        startDate = today.startOf('year').format('DD-MM-YYYY');
        break;
  
      case 'month':
        startDate = today.startOf('month').format('DD-MM-YYYY');
        break;
  
      case 'week':
        startDate = today.startOf('week').format('DD-MM-YYYY');
        break;
  
      case 'today':
        startDate = today.format('DD-MM-YYYY');
        break;  
      default:
        console.warn('Invalid range selected');
        return;
    }
    setSelectedDateType(selectedValue);
    setRange({ startDate: dateToIso(startDate), endDate: dateToIso(endDate) });
    }, []);
    
  return (
    <div>
      <div
        className='gap-4 p-2'
        style={{
          display: "inline-flex",
          background: "white",
          zIndex: 9999,
          position: "absolute",
          borderRadius: "8px",
          top: 24,
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        {values.map(el => {
          return (
            <Radio
              value={selectedOption}
              option={el}
              onChange={el => setSelectedOption(el as TSnapshotType)}
            />
          );
        })}
      </div>
      <div style={{
          display: "inline-flex",
          position: "absolute",
          zIndex: 1,
          top: 24,
          left: 24,
        }}>
        <SelectComponent
          items={items}
          loading={false}
          multiple={false}
          modelValue={selectedDateType}
          itemValue="id"
          itemText="name"
          onSelect={handleSelect}
          placeholder=""
          range={range}
          setRange={setRange}
        />
      </div>
      {data?.data && !isLoading && (
        <GoogleMapsHeatmap heatmapData={data?.data} />
      )}
    </div>
  );
};
