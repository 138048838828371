import React, { FC, ReactNode, useEffect } from "react";
import { Link } from "react-router-dom";
import { TIconsNames } from "../../iconSets";
import Icon from "../Icon";
import "./style.scss";

interface ButtonProps {
  size?: "large" | "sm" | "xs" | "square";
  main?: boolean;
  color?: "green" | "blue" | "purple" | "red" | "black";
  block?: boolean;
  disabled?: boolean;
  type?: "button" | "submit";
  beforeIcon?: TIconsNames;
  afterIcon?: TIconsNames;
  beforeClass?: string;
  afterClass?: string;
  href?: string;
  to?: string;
  loading?: boolean;
  target?: string;
  children?: ReactNode;
  before?: ReactNode;
  after?: ReactNode;
  onClick?: () => void;
  id?: string;
  enter?: boolean;
  esc?: boolean;
  className?: string;
}

const Button: FC<ButtonProps> = ({
  size,
  main,
  color,
  block,
  disabled,
  type = "button",
  beforeIcon,
  afterIcon,
  beforeClass,
  afterClass,
  href,
  to,
  loading,
  target,
  children,
  before,
  after,
  onClick,
  id,
  enter,
  esc,
  className = '',
}) => {
  const rootComponent: any = href ? "a" : to ? Link : "button";

  const classNames = [
    `buttonG ${className}`,
    main && "buttonG--main",
    block && "buttonG--block",
    size && `buttonG--${size}`,
    color && `buttonG--${color}`,
    afterIcon && `buttonG--after`,
  ]
    .filter(Boolean)
    .join(" ");

  const buttonProps = {
    className: classNames,
    ...(disabled ? { disabled } : {}),
    ...(href ? { href, target } : {}),
    ...(to ? { to } : {}),
    ...(type ? { type } : {}),
    onClick,
    id,
  };

  useEffect(() => {
    if (!(enter || esc) || disabled) return;

    const listener = (event: KeyboardEvent) => {
      if (event.code === "Enter" && enter) {
        onClick?.();
        event.preventDefault();
      } else if (event.code === "Escape" && esc) {
        onClick?.();
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [enter, esc, disabled, onClick]);

  return React.createElement(
    rootComponent,
    buttonProps,
    <>
      {!loading && (beforeIcon || before) && (
        <div className={`buttonG__before ${beforeClass || ""}`}>
          {before || (beforeIcon && <Icon icon={beforeIcon} size='20' />)}
        </div>
      )}
      {loading && (
        <div className='px-6'>
          <div className='spinner'></div>
        </div>
      )}
      {!loading && children}
      {!loading && (afterIcon || after) && (
        <div className={`buttonG__after ${afterClass || ""}`}>
          {after || (afterIcon && <Icon icon={afterIcon} size='20' />)}
        </div>
      )}
    </>,
  );
};

export default Button;
