import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, HeatmapLayer } from "@react-google-maps/api";
import { TSnapshotType } from "../../components/heatmap/types";
import { Radio } from "../../components/common/form/Radio";

const GOOGLE_MAPS_API_KEY = "AIzaSyAnqHNOO9ZXfeziJ1oVsxJrj49q6Uf25Og";

interface HeatmapData {
  lat: number;
  lng: number;
}

const GoogleMapsHeatmap: React.FC<{
  heatmapData: HeatmapData[];
}> = ({ heatmapData }) => {
  const [mapLoaded, setMapLoaded] = useState(false);

  const onLoad = () => {
    setMapLoaded(true);
  };

  return (
    <LoadScript
      googleMapsApiKey={GOOGLE_MAPS_API_KEY}
      // googleMapsApiKey=''
      libraries={["visualization"]}
      
    >
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "calc(100vh - 64px)" }}
        center={heatmapData[0]}
        zoom={8}
        onLoad={onLoad}
      >
        {mapLoaded && (
          <HeatmapLayer
            data={heatmapData.map(
              point => new window.google.maps.LatLng(point.lat, point.lng),
            )}
            options={{
              radius: 20,
              opacity: 0.7,
            }}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapsHeatmap;
