import React from "react";
import { icons, TIconsNames } from "../iconSets";

interface IconComponentProps {
  size?: number | string;
  color?: string;
  icon: TIconsNames;
  className?: string;
}

const Icon: React.FC<IconComponentProps> = ({
  size = "20px",
  color,
  icon,
  className = "",
}) => {
  const IconSrc = icons[icon];
  if (color) {
    return (
      <div
        className={className}
        style={{
          width: size,
          height: size,
          backgroundColor: color,
          maskImage: `url(${IconSrc})`,
          WebkitMaskImage: `url(${IconSrc})`,
          maskRepeat: "no-repeat",
          WebkitMaskRepeat: "no-repeat",
          maskSize: "contain",
          WebkitMaskSize: "contain",
        }}
      />
    );
  }

  return (
    <img
      src={IconSrc}
      alt={icon}
      style={{ width: size }}
      className={className}
    />
  );
};

export default Icon;
