import React, { Ref, forwardRef, useEffect, useRef } from "react";
import "./style.scss";
import TextTitle from "../common/ui/typography/TextTitle";
import Button from "../common/ui/buttons/Button";
import useStore from "./store";
import Icon from "../common/ui/Icon";
import TextCaption from "../common/ui/typography/TextCaption";
import ButtonGroupTab from "../common/ui/buttonGroupTab/ButtonGroupTab";
import DatePickerInput from "../common/form/DatePickerInput";
import CompaniesStatistics from "./components/companiesStatistics/CompaniesStatistics";
import { useStatisticsList } from "../../hooks/statistics/useStatisticsList";
import moment from "moment";
import { TStatistics } from "../../api/statistics/types";
import { useFinancialMetrics } from "../../hooks/statistics/useFinancialMetrics";
import { Tabs } from "../common/ui/tabs/Tabs";
import { Tab } from "../common/ui/tabs/Tab";
import { TIconsNames } from "../common/iconSets";

const SkeletonCard = () => {
  return (
    <div className='overview__card skeleton'>
      <div className='overview__card__header'>
        <div className='skeleton__text skeleton__text--title'></div>
        <div className='skeleton__icon'></div>
      </div>
      <div className='overview__card__body'>
        <div className='skeleton__text skeleton__text--large'></div>
        <div className='d-flex gap-1'>
          <div className='skeleton__icon'></div>
          <div className='skeleton__text skeleton__text--caption'></div>
          <div className='skeleton__text skeleton__text--caption'></div>
        </div>
      </div>
    </div>
  );
};
const statisticTypes: string[] = [
  'company',
  'subscription',
  'vehicle',
  'driver',
  'user',
];

const OverviewPage = () => {
  const isInitialMount = useRef(true);
  const activeOption = useStore(state => state.activeOption);
  const setActiveOption = useStore(state => state.setActiveOption);
  const startDate = useStore(state => state.startDate);
  const endDate = useStore(state => state.endDate);
  const setStartDate = useStore(state => state.setStartDate);
  const setEndDate = useStore(state => state.setEndDate);
  const resetStore = useStore(state => state.resetStore);
  const setDriverData = useStore(state => state.setDriverData);
  const setVehicleData = useStore(state => state.setVehicleData);
  const setCompanyData = useStore(state => state.setCompanyData);
  const setSubscriptionData = useStore(state => state.setSubscriptionData);
  const setSubscriptionCompanyData = useStore(
    state => state.setSubscriptionCompanyData,
  );
  const setUserData = useStore(state => state.setUserData);
  const setIsLoadingData = useStore(state => state.setIsLoadingData);
  const handleTabChange = useStore(state => state.handleTabChange);
  const activeTabIndex = useStore(state => state.activeTabIndex);
  const {
    data,
    isLoading,
    refetch,
  }: {
    data:
      | {
          driverData: TStatistics;
          vehicleData: TStatistics;
          companyData: TStatistics;
          subscriptionData: TStatistics;
          subscriptionCompanyData: TStatistics;
          userData: TStatistics;
        }
      | undefined;
    refetch: () => void;
    isLoading: boolean;
  } = useStatisticsList(
    moment(startDate).toISOString(),
    moment(endDate).toISOString(),
    (statisticTypes[activeTabIndex] || '')
  );
  const { data: financial } = useFinancialMetrics();
  useEffect(() => {
    setIsLoadingData(isLoading);
    if (data?.driverData) {
      setDriverData(data?.driverData);
    }
    if (data?.vehicleData) {
      setVehicleData(data?.vehicleData);
    }
    if (data?.companyData) {
      setCompanyData(data?.companyData);
    }
    if (data?.subscriptionData) {
      setSubscriptionData(data?.subscriptionData);
    }
    if (data?.subscriptionCompanyData) {
      setSubscriptionCompanyData(data?.subscriptionCompanyData);
    }
    if (data?.userData) {
      setUserData(data?.userData);
    }
  }, [data, isLoading]);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      return () => {
        resetStore();
      };
    }
  }, []);

  const handleOptionSelect = (selectedOption: {
    title: string;
    value: string;
  }) => {
    const endOfDay = moment().endOf("day").toDate();
    switch (selectedOption.value) {
      case "day":
        setStartDate(moment(endOfDay).add(-1, "day").toDate());
        setEndDate(endOfDay);
        break;
      case "week":
        setStartDate(moment(endOfDay).add(-1, "week").toDate());
        setEndDate(endOfDay);
        break;
      case "month":
        setStartDate(moment(endOfDay).add(-1, "month").toDate());
        setEndDate(endOfDay);
        break;
      case "year":
        setStartDate(moment(endOfDay).add(-1, "year").toDate());
        setEndDate(endOfDay);
    }
    setActiveOption(selectedOption);
  };
  const tabs = [
    { title: "Day", value: "day" },
    { title: "Week", value: "week" },
    { title: "Month", value: "month" },
    { title: "Year", value: "year" },
    { title: "Custom time range", value: "custom" },
  ];

  type TFinancialKeys = "company" | "vehicle" | "driver" | "subscription" | "user";
  const financialKeys: TFinancialKeys[] = ["company", "vehicle", "driver", "subscription", "user"];
  const cardIcons: Record<TFinancialKeys, TIconsNames> = {
    company: 'building-filled',
    vehicle: 'truck-regular-filled',
    driver: 'person',
    subscription: 'dolla-filled',
    user: 'people-filled'
  }

  return (
    <div className='overview'>
      <div className='overview__header'>
        <TextTitle level='large'>Today, {moment().format("D MMMM")}</TextTitle>
        <Button beforeIcon='arrow-clockwise' onClick={refetch}>
          Refresh
        </Button>
      </div>
      {financial?.driverData &&
      financial?.companyData &&
      financial?.vehicleData ? (
        <div className='overview__body'>
          <div className='overview__body__infocpm'>
            {financialKeys.map(key => (
              <div className='overview__card' key={key}>
                <div className='overview__card__header'>
                  <Icon color="#9B9DAA" icon={cardIcons[key]} />
                  <TextTitle level='2'>{key === 'user' ? 'Total Active Users' : (`Total ${key === "company" ? "companies" : key + "s"}`)}</TextTitle>
                </div>
                <div className='overview__card__body'>
                  <TextTitle level='large'>
                    {financial[`${key}Data`]?.total?.toLocaleString()}
                  </TextTitle>
                  <div
                    className='d-flex gap-1'
                    style={{
                      color:
                        financial[`${key}Data`]?.totalDifference > 0
                          ? "#27AE60"
                          : financial[`${key}Data`]?.totalDifference === 0
                            ? "#9b9daa"
                            : "#F64747",
                    }}
                  >
                    <Icon
                      icon={
                        financial[`${key}Data`]?.totalDifference > 0
                          ? "arrow-trending-filled"
                          : financial[`${key}Data`]?.totalDifference === 0
                            ? "arrow-right-filled"
                            : "arrow-trending-filled-red"
                      }
                    />
                    <TextCaption level='2'>
                      {financial[`${key}Data`]?.totalDifference > 0 ? "+" : ""}
                      {parseFloat(
                        financial[`${key}Data`]?.totalDifference?.toFixed(1),
                      )}{" "}
                      (
                      {parseFloat(
                        financial[`${key}Data`]?.totalPercentage?.toFixed(1),
                      )}
                      %)
                    </TextCaption>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className='overview__body__fmetrics'>
              <div className='overview__body__fmetrics--header'>
                <TextTitle level='2'>Financial Metrics</TextTitle>
                {/* <Icon icon='info' /> */}
          {/* </div>
              <div className='d-flex w-100 gap-3 flex-wrap'>
                <div className='overview__body__fmetrics__info'>
                  <TextCaption level='4'>
                    Daily Recurring Revenue (DRR)
                  </TextCaption>
                  <TextTitle level='1'>
                    $
                    {financial?.financialData?.daily?.toLocaleString() ||
                      "000,000.00"}
                  </TextTitle>
                </div>
                <div className='overview__body__fmetrics__info'>
                  <TextCaption level='4'>
                    Monthly Recurring Revenue (MRR)
                  </TextCaption>
                  <TextTitle level='1'>
                    $
                    {financial?.financialData?.monthly?.toLocaleString() ||
                      "000,000.00"}
                  </TextTitle>
                </div>
                <div className='overview__body__fmetrics__info'>
                  <TextCaption level='4'>
                    Annual Recurring Revenue (ARR)
                  </TextCaption>
                  <TextTitle level='1'>
                    $
                    {financial?.financialData?.annual?.toLocaleString() ||
                      "000,000.00"}
                  </TextTitle>
                </div>
              </div> */}
          {/* </div> */}
        </div>
      ) : (
        <div className='overview__body'>
          <div className='overview__body__infocpm'>
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
          </div>
        </div>
      )}
      <div style={{marginLeft: 24}}>
        <TextTitle level='1'>Statistics</TextTitle>
      </div>
      
      <Tabs modelValue={activeTabIndex} onTabChange={handleTabChange}>
        <Tab
          icon='building'
          activeIcon='building-filled'
          text='Companies'
        />
        <Tab icon='dolla' activeIcon='dolla-filled' text='Subscriptions' />
        <Tab icon='truck-regular' activeIcon='truck-regular-filled' text='Vehicles' />
        <Tab icon='person2' activeIcon='person2-filled' text='Drivers' />
        <Tab icon='people' activeIcon='people-filled' text='User Engagements' />
      </Tabs>
      <div className='overview__statistics'>
        <div className='overview__statistics--buttons'>
          {activeOption && (
            <ButtonGroupTab
              options={tabs}
              defaultActive={activeOption}
              onOptionSelect={handleOptionSelect}
            />
          )}
          {activeOption?.value === "custom" && (
            <div className='date-pickers d-flex'>
              <DatePickerInput
                value={startDate}
                onChange={(date: Date | null) => setStartDate(date)}
                dateFormat='MMM d, yyyy'
                customClassName='date-picker-input custom-datepicker'
                maxDate={endDate || new Date()}
                placeholder='From'
                afterIcon='chevron-down'
                beforeIcon='calendar'
              />
              <DatePickerInput
                value={endDate}
                onChange={date => setEndDate(date)}
                dateFormat='MMM d, yyyy'
                customClassName='date-picker-input custom-datepicker'
                maxDate={new Date()}
                minDate={startDate || undefined}
                placeholder='To'
                afterIcon='chevron-down'
                beforeIcon='calendar'
              />
            </div>
          )}
        </div>
        <CompaniesStatistics statisticType={statisticTypes[activeTabIndex]} />
      </div>
    </div>
  );
};

export default OverviewPage;
