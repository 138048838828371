import React, { useState, useRef, useEffect, ChangeEvent, useCallback } from "react";
import {
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import TextInput from "../../form/TextInput";
import { Radio } from "../../form/Radio";
import "./dropdown.scss";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import { dateToIso } from "../../../utils";
import Button from "../buttons/Button";
interface SelectComponentProps<T> {
  items: T[];
  loading: boolean;
  multiple: boolean;
  placeholder: string;
  modelValue: any;
  itemValue: string;
  itemText: string;
  onSearch?: (value: string) => void;
  onSelect?: (value: any) => void;
  onClear?: () => void;
  range: { startDate: string; endDate: string };
  setRange: (range: { startDate: string; endDate: string }) => void;
}

const SelectComponent = <T extends Record<string, any>>({
  items = [],
  loading = false,
  multiple = false,
  placeholder = "Select...",
  modelValue = [],
  itemValue = "id",
  itemText = "name",
  onSearch,
  onSelect,
  onClear,
  range,
  setRange,
  ...props
}: SelectComponentProps<T>) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [selected, setSelected] = useState<any>(multiple ? [] : null);
  const [filteredItems, setFilteredItems] = useState<T[]>(items);
  const [state, setState] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  useEffect(() => {
    if (multiple) {
      const selectedItems = items.filter(item =>
        modelValue.includes(item[itemValue]),
      );
      setSelected(selectedItems);
    } else {
      const selectedItem = items.find(item => item[itemValue] === modelValue);
      setSelected(selectedItem || null);
    }
  }, [modelValue, items, multiple, itemValue]);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const handleSearch = (value: string) => {
    setSearchVal(value);
    if (onSearch) onSearch(value);
    else {
      const filtered = items.filter(item =>
        item[itemText].toLowerCase().includes(value.toLowerCase()),
      );
      setFilteredItems(filtered);
    }
  };

  const handleSelect = (item: T) => {
    if (item.disabled) return;
    if (multiple) {
      const updated = selected.some(
        (s: any) => s[itemValue] === item[itemValue],
      )
        ? selected.filter((s: any) => s[itemValue] !== item[itemValue])
        : [...selected, item];
      setSelected(updated);
      onSelect && onSelect(updated.map((i: any) => i[itemValue]));
    } else {
      setSelected(item);
      onSelect && onSelect(item[itemValue]);
    }
  };

  const isSelected = (item: T) => {
    return multiple
      ? (selected as T[]).some(s => s[itemValue] === item[itemValue])
      : (selected as T) && (selected as T)[itemValue] === item[itemValue];
  };

  const isCustomSelected = isSelected({
    [itemValue]: "custom",
    [itemText]: "Custom Range",
  } as any);

  const handleDateRangeChange = (ranges: any) => {
    const { selection } = ranges;
    setState([selection]);
  };

  const hadleShowCustomRange = useCallback(() => {
    handleSelect({
      [itemValue]: "custom",
      [itemText]: "Custom Range",
    } as any)
    setRange({
      startDate: dateToIso(moment(state[0].startDate).format('DD-MM-YYYY')),
      endDate: dateToIso(moment(state[0].endDate).format('DD-MM-YYYY')),
    })
  }, [state])

  return (
    <div className='select-heatmap'>
      <div onClick={() => setMenuOpen(true)}>
      <TextInput
        type='text'
        placeholder={placeholder}
        value={
          searchVal ||
          (multiple
            ? (selected as T[]).map(s => s[itemText]).join(", ")
            : (selected as T)?.[itemText] || "")
        }
        onChange={handleSearch}
        onClickExtra={() => setMenuOpen(true)}
        beforeIcon='calendar'
        {...props}
      />
      </div>
      
      <Dropdown
        isOpen={menuOpen}
        toggle={toggleMenu}
        className='select-dropdown mt-2'
      >
        <DropdownToggle
          tag='div'
          data-toggle='dropdown'
          aria-expanded={menuOpen}
        ></DropdownToggle>
        <DropdownMenu className='select-menu' style={{ width: "240px" }}>
          {loading ? (
            <div className='select-menu-loading text-center p-2'>
              <Spinner size='sm' />
            </div>
          ) : filteredItems.length > 0 ? (
            filteredItems.map((item, index) => (
              <DropdownItem
                key={index}
                onClick={() => handleSelect(item)}
                className={
                  (isSelected(item) ? "selected" : "") + `${item[itemValue] === 'custom' ? " dropdown-item-custom" : " dropdown-item"}`
                }
                disabled={item.disabled}
                style={{ padding: 8 }}
                toggle={item[itemValue] !== 'custom'}
              >
                <Radio
                  key={index}
                  option={{ value: item[itemValue], label: item[itemText] }}
                  value={
                    (selected || {})[itemValue]
                      ? (selected || {})[itemValue]
                      : ""
                  }
                />
              </DropdownItem>
            ))
          ) : (
            <div className='select-menu-empty text-center p-2'>
              No data found
            </div>
          )}
          {isCustomSelected && <div style={{ width: "100%" }}>
            <DateRange
              editableDateInputs={true}
              onChange={handleDateRangeChange}
              moveRangeOnFirstSelection={false}
              ranges={state}
              className='react-date-range'
              showDateDisplay={false}
              rangeColors={["#1D72E9"]}
            />
            <Button onClick={hadleShowCustomRange} main className='w-100'>
              Show
            </Button>
          </div>}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default SelectComponent;
